<template>
  <div>
    <v-card class="d-flex justify-end mb-6" flat tile>
      <v-breadcrumbs :items="breadcrumbs" large>
        <template v-slot:divider>
          <v-icon>{{ icons.mdiSlashForward }} </v-icon>
        </template>
      </v-breadcrumbs>
    </v-card>
    <v-card flat class="pa-3 mt-2">
      <!-- Modal -->
      <div id="exampleModal" class="modal fade" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 id="exampleModalLabel" class="modal-title">KATEGORI MEMBER</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <v-text-field
                v-model="search"
                class="pt-5"
                label="Search"
                dense
                outlined
                @keyup="doSearch()"
              ></v-text-field>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-uppercase">NO</th>
                      <th class="text-center text-uppercase">NAMA KATEGORI MEMBER</th>
                      <th class="text-center text-uppercase">ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(data, index) in displayData()" :key="index">
                      <td>{{ index + 1 }}</td>
                      <td class="text-center">
                        {{ data.nama_kategorimember | capitalize }}
                      </td>

                      <td class="text-center">
                        <v-btn
                          data-bs-dismiss="modal"
                          color="primary"
                          class="me-3 mt-4"
                          @click="PilihKategoriMember(data.id_kategorimember, data.nama_kategorimember)"
                        >
                          <v-icon color="#ECEFF1">
                            {{ icons.mdiCheckBold }}
                          </v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="6">
                        <center>
                          <div class="v-data-footer">
                            <!-- <div class="v-data-footer__pagination" bis_skin_checked="1">1-10 of 100</div> -->
                            <span class="v-data-footer__icons-before" bis_skin_checked="1">
                              <button
                                type="button"
                                :class="[
                                  'v-btn  v-btn--icon v-btn--round v-btn--text theme--light v-size--default',
                                  page == 1 ? 'v-btn--disabled' : '',
                                ]"
                                aria-label="Previous page"
                                @click="page--"
                              >
                                <span class="v-btn__content"
                                  ><span aria-hidden="true" class="v-icon notranslate theme--light"
                                    ><svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      role="img"
                                      aria-hidden="true"
                                      class="v-icon__svg"
                                    >
                                      <path
                                        d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z"
                                      ></path></svg></span
                                ></span>
                              </button>
                            </span>
                            <span
                              v-for="pageNumber in pages.slice(page - 1, page + 5)"
                              :key="pageNumber"
                              :class="['v-data-footer', pageNumber == page ? 'active' : '']"
                              @click="page = pageNumber"
                            >
                              <!-- <a href="#">{{ pageNumber }}</a> -->
                              <button
                                type="button"
                                class="v-btn v-btn--icon v-btn--round v-btn--text theme--light v-size--default"
                                aria-label="Next page"
                              >
                                {{ pageNumber }}
                              </button>
                            </span>
                            <span class="v-data-footer__icons-after" bis_skin_checked="1">
                              <button
                                type="button"
                                :class="[
                                  'v-btn v-btn--icon v-btn--round v-btn--text theme--light v-size--default',
                                  page < pages.length ? '' : 'v-btn--disabled',
                                ]"
                                aria-label="Next page"
                                @click="page++"
                              >
                                <span class="v-btn__content"
                                  ><span aria-hidden="true" class="v-icon notranslate theme--light"
                                    ><svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      role="img"
                                      aria-hidden="true"
                                      class="v-icon__svg"
                                    >
                                      <path
                                        d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"
                                      ></path></svg></span
                                ></span>
                              </button>
                            </span>
                          </div>
                        </center>
                      </td>
                    </tr>
                  </tfoot>
                </template>
              </v-simple-table>
            </div>
          </div>
        </div>
      </div>
      <!-- end Modal -->

      <!-- Modal -->
      <div id="ModalKelurahan" class="modal fade" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 id="exampleModalLabel" class="modal-title">KELURAHAN</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <v-text-field
                v-model="searchNegaraAsal"
                class="pt-5"
                label="Search"
                dense
                outlined
                @keyup="doSearchNegaraAsal()"
              ></v-text-field>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-uppercase">NO</th>
                      <th class="text-center text-uppercase">NAMA KELURAHAN</th>
                      <th class="text-center text-uppercase">ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(data, index) in displayDataKeluarahan()" :key="index">
                      <td>{{ index + 1 }}</td>
                      <td class="text-center">
                        {{ data.nama_kelurahan | capitalize }}
                      </td>
                      <td class="text-center">
                        <v-btn
                          data-bs-dismiss="modal"
                          color="primary"
                          class="me-3 mt-4"
                          @click="PilihKelurahan(data.id_kelurahan, data.nama_kelurahan)"
                        >
                          <v-icon color="#ECEFF1">
                            {{ icons.mdiCheckBold }}
                          </v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="6">
                        <center>
                          <div class="v-data-footer">
                            <!-- <div class="v-data-footer__pagination" bis_skin_checked="1">1-10 of 100</div> -->
                            <div class="v-data-footer__icons-before" bis_skin_checked="1">
                              <button
                                type="button"
                                :class="[
                                  'v-btn  v-btn--icon v-btn--round v-btn--text theme--light v-size--default',
                                  pageNegaraAsal == 1 ? 'v-btn--disabled' : '',
                                ]"
                                aria-label="Previous page"
                                @click="pageNegaraAsal--"
                              >
                                <span class="v-btn__content"
                                  ><span aria-hidden="true" class="v-icon notranslate theme--light"
                                    ><svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      role="img"
                                      aria-hidden="true"
                                      class="v-icon__svg"
                                    >
                                      <path
                                        d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z"
                                      ></path></svg></span
                                ></span>
                              </button>
                            </div>
                            <div
                              v-for="pageNumber in pagesNegaraAsal.slice(pageNegaraAsal - 1, pageNegaraAsal + 5)"
                              :key="pageNumber"
                              :class="['v-data-footer', pageNumber == pageNegaraAsal ? 'active' : '']"
                              @click="pageNegaraAsal = pageNumber"
                            >
                              <!-- <a href="#">{{ pageNumber }}</a> -->
                              <button
                                type="button"
                                class="v-btn v-btn--icon v-btn--round v-btn--text theme--light v-size--default"
                                aria-label="Next page"
                              >
                                {{ pageNumber }}
                              </button>
                            </div>
                            <div class="v-data-footer__icons-after" bis_skin_checked="1">
                              <button
                                type="button"
                                :class="[
                                  'v-btn v-btn--icon v-btn--round v-btn--text theme--light v-size--default',
                                  pageNegaraAsal < pagesNegaraAsal.length ? '' : 'v-btn--disabled',
                                ]"
                                aria-label="Next page"
                                @click="pageNegaraAsal++"
                              >
                                <span class="v-btn__content"
                                  ><span aria-hidden="true" class="v-icon notranslate theme--light"
                                    ><svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      role="img"
                                      aria-hidden="true"
                                      class="v-icon__svg"
                                    >
                                      <path
                                        d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"
                                      ></path></svg></span
                                ></span>
                              </button>
                            </div>
                          </div>
                        </center>
                      </td>
                    </tr>
                  </tfoot>
                </template>
              </v-simple-table>
            </div>
          </div>
        </div>
      </div>
      <!-- end Modal -->

      <!-- Modal Kelurahan Toko -->
      <div
        id="ModalKelurahanToko"
        class="modal fade"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 id="exampleModalLabel" class="modal-title">KELURAHAN</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <v-text-field
                v-model="searchNegaraAsal"
                class="pt-5"
                label="Search"
                dense
                outlined
                @keyup="doSearchNegaraAsal()"
              ></v-text-field>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-uppercase">NO</th>
                      <th class="text-center text-uppercase">NAMA KELURAHAN</th>
                      <th class="text-center text-uppercase">ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(data, index) in displayDataKeluarahan()" :key="index">
                      <td>{{ index + 1 }}</td>
                      <td class="text-center">
                        {{ data.nama_kelurahan | capitalize }}
                      </td>
                      <td class="text-center">
                        <v-btn
                          data-bs-dismiss="modal"
                          color="primary"
                          class="me-3 mt-4"
                          @click="PilihKelurahanToko(data.id_kelurahan, data.nama_kelurahan)"
                        >
                          <v-icon color="#ECEFF1">
                            {{ icons.mdiCheckBold }}
                          </v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="6">
                        <center>
                          <div class="v-data-footer">
                            <!-- <div class="v-data-footer__pagination" bis_skin_checked="1">1-10 of 100</div> -->
                            <div class="v-data-footer__icons-before" bis_skin_checked="1">
                              <button
                                type="button"
                                :class="[
                                  'v-btn  v-btn--icon v-btn--round v-btn--text theme--light v-size--default',
                                  pageNegaraAsal == 1 ? 'v-btn--disabled' : '',
                                ]"
                                aria-label="Previous page"
                                @click="pageNegaraAsal--"
                              >
                                <span class="v-btn__content"
                                  ><span aria-hidden="true" class="v-icon notranslate theme--light"
                                    ><svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      role="img"
                                      aria-hidden="true"
                                      class="v-icon__svg"
                                    >
                                      <path
                                        d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z"
                                      ></path></svg></span
                                ></span>
                              </button>
                            </div>
                            <div
                              v-for="pageNumber in pagesNegaraAsal.slice(pageNegaraAsal - 1, pageNegaraAsal + 5)"
                              :key="pageNumber"
                              :class="['v-data-footer', pageNumber == pageNegaraAsal ? 'active' : '']"
                              @click="pageNegaraAsal = pageNumber"
                            >
                              <!-- <a href="#">{{ pageNumber }}</a> -->
                              <button
                                type="button"
                                class="v-btn v-btn--icon v-btn--round v-btn--text theme--light v-size--default"
                                aria-label="Next page"
                              >
                                {{ pageNumber }}
                              </button>
                            </div>
                            <div class="v-data-footer__icons-after" bis_skin_checked="1">
                              <button
                                type="button"
                                :class="[
                                  'v-btn v-btn--icon v-btn--round v-btn--text theme--light v-size--default',
                                  pageNegaraAsal < pagesNegaraAsal.length ? '' : 'v-btn--disabled',
                                ]"
                                aria-label="Next page"
                                @click="pageNegaraAsal++"
                              >
                                <span class="v-btn__content"
                                  ><span aria-hidden="true" class="v-icon notranslate theme--light"
                                    ><svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      role="img"
                                      aria-hidden="true"
                                      class="v-icon__svg"
                                    >
                                      <path
                                        d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"
                                      ></path></svg></span
                                ></span>
                              </button>
                            </div>
                          </div>
                        </center>
                      </td>
                    </tr>
                  </tfoot>
                </template>
              </v-simple-table>
            </div>
          </div>
        </div>
      </div>
      <!-- end Modal -->

      <v-card-text>
        <v-card-title class="text-no-wrap pt-1 ps-2"> UPDATE MEMBER </v-card-title>
        <form ref="form" enctype="multipart/form-data" @submit.prevent="CreateData">
          <v-row>
            <v-col v-if="errors.length" cols="12">
              <v-alert v-for="error in errors" :key="error" color="error" text class="mb-0">
                <div class="d-flex align-start">
                  <v-icon color="warning"> </v-icon>
                  <div class="ms-3">
                    <p class="text-base font-weight-medium mb-1">
                      {{ error }}
                    </p>
                  </div>
                </div>
              </v-alert>
            </v-col>

            <v-col md="12" cols="12">
              <v-header>Jenis Usaha</v-header>
              <v-radio-group v-model="drivers.id_jenisusaha" row>
                <v-radio
                  v-for="(ju, index) in jenisusaha"
                  :key="index"
                  :label="ju.nama_jenisusaha | capitalize"
                  :value="ju.id_jenisusaha"
                  color="info"
                  @click="ClearData()"
                ></v-radio>
                <!-- <v-radio label="Tidak" value="T" color="error"></v-radio> -->
              </v-radio-group>
            </v-col>

            <v-col md="6" cols="12" v-if="drivers.id_jenisusaha == '1a2040e8-c61f-4d66-a93e-057320e1ee6d'">
              <v-text-field
                v-model="drivers.no_platmotor"
                label="Nomor Plat Kendaraan"
                class="required"
                dense
                outlined
              ></v-text-field>
            </v-col>

            <v-col md="6" cols="12" v-if="drivers.id_jenisusaha == '1a2040e8-c61f-4d66-a93e-057320e1ee6d'">
              <v-combobox solo v-model="drivers.merk_kendaraan" :items="merkmotor" label="Merk Kendaraan"> </v-combobox>
            </v-col>
            <v-col md="6" cols="12" v-if="drivers.id_jenisusaha == '1a2040e8-c61f-4d66-a93e-057320e1ee6d'">
              <v-text-field
                v-model="drivers.tahun_pembuatan_kendaraan"
                label="Tahun Pembuatan"
                class="required"
                dense
                outlined
              ></v-text-field>
            </v-col>
            <v-col md="6" cols="12" v-if="drivers.id_jenisusaha == '1a2040e8-c61f-4d66-a93e-057320e1ee6d'">
              <v-text-field
                v-model="drivers.no_rekening"
                label="Nomor Rekening"
                class="required"
                dense
                outlined
              ></v-text-field>
            </v-col>
            <v-col md="6" cols="12" v-if="drivers.id_jenisusaha == '1a2040e8-c61f-4d66-a93e-057320e1ee6d'">
              <v-text-field
                v-model="drivers.nama_bank"
                label="Nama Bank"
                class="required"
                dense
                outlined
              ></v-text-field>
            </v-col>

            <v-col md="6" cols="12" v-if="drivers.id_jenisusaha == '1a2040e8-c61f-4d66-a93e-057320e1ee6d'">
              <v-header>Aktif</v-header>
              <v-radio-group v-model="drivers.aktif" row>
                <v-radio label="Ya" value="Y" color="info"></v-radio>
                <v-radio label="Tidak" value="T" color="error"></v-radio>
              </v-radio-group>
            </v-col>

            <v-col md="6" cols="12" v-if="drivers.id_jenisusaha == '1a2040e8-c61f-4d66-a93e-057320e1ee6d'">
              <v-header>Blokir</v-header>
              <v-radio-group v-model="drivers.blokir" row>
                <v-radio label="Ya" value="Y" color="info"></v-radio>
                <v-radio label="Tidak" value="T" color="error"></v-radio>
              </v-radio-group>
            </v-col>

            <v-col md="12" cols="12" v-if="drivers.id_jenisusaha == '1a2040e8-c61f-4d66-a93e-057320e1ee6d'">
              <v-card-text class="text-no-wrap pt-1 ps-1"> Photo SIM</v-card-text>
              <!-- <v-img :lazy-src="showPhoto()" max-height="150" max-width="150" :src="showPhoto()"></v-img> -->
              <div class="cropper-area">
                <div class="img-cropper">
                  <vue-cropper
                    ref="cropperSim"
                    :key="showPhotoSim"
                    :aspect-ratio="4 / 2"
                    :src="showPhotoSim()"
                    preview=".preview"
                  />
                </div>
              </div>

              <v-btn type="submit" color="primary" @click.prevent="showFileChooserSim" class="me-3 mt-4">
                Choose Image
              </v-btn>
              <v-btn type="submit" color="primary" @click.prevent="cropImageSim" class="me-3 mt-4"> Crop </v-btn>

              <input style="display: none" ref="inputSim" type="file" accept="image/*" @change="setImageSim" />
              <br />
              <br />
              <v-card-text class="text-no-wrap pt-1 ps-1"> Crop Photo SIM</v-card-text>
              <section class="preview-area">
                <!-- <p>Cover</p> -->
                <div class="cropped-image">
                  <img width="200px" :src="updatePhotoSim()" />
                  <!-- <img width="200px" :src="clients.photo == '' ? defautlPhoto() : clients.photo" alt="Cropped Image" /> -->
                </div>
              </section>
            </v-col>

            <v-col md="12" cols="12" v-if="drivers.id_jenisusaha == '1a2040e8-c61f-4d66-a93e-057320e1ee6d'">
              <v-card-text class="text-no-wrap pt-1 ps-1"> Photo Bawa SIM</v-card-text>
              <!-- <v-img :lazy-src="showPhoto()" max-height="150" max-width="150" :src="showPhoto()"></v-img> -->
              <div class="cropper-area">
                <div class="img-cropper">
                  <vue-cropper
                    ref="cropperBawaSim"
                    :key="showPhotoBawaSim"
                    :aspect-ratio="4 / 2"
                    :src="showPhotoBawaSim()"
                    preview=".preview"
                  />
                </div>
              </div>

              <v-btn type="submit" color="primary" @click.prevent="showFileChooserBawaSim" class="me-3 mt-4">
                Choose Image
              </v-btn>
              <v-btn type="submit" color="primary" @click.prevent="cropImageBawaSim" class="me-3 mt-4"> Crop </v-btn>

              <input style="display: none" ref="inputBawaSim" type="file" accept="image/*" @change="setImageBawaSim" />
              <br />
              <br />
              <v-card-text class="text-no-wrap pt-1 ps-1"> Crop Photo Bawa SIM</v-card-text>
              <section class="preview-area">
                <!-- <p>Cover</p> -->
                <div class="cropped-image">
                  <img width="200px" :src="updatePhotoBawaSim()" />
                  <!-- <img width="200px" :src="clients.photo == '' ? defautlPhoto() : clients.photo" alt="Cropped Image" /> -->
                </div>
              </section>
            </v-col>

            <!-- Toko -->
            <v-col md="6" cols="12" v-if="drivers.id_jenisusaha != '1a2040e8-c61f-4d66-a93e-057320e1ee6d'">
              <v-text-field v-model="tokos.nama_toko" label="Nama Toko" class="required" dense outlined></v-text-field>
            </v-col>

            <v-col md="6" cols="12" v-if="drivers.id_jenisusaha != '1a2040e8-c61f-4d66-a93e-057320e1ee6d'">
              <v-text-field
                v-model="tokos.alamat_toko"
                label="Alamat Toko"
                class="required"
                dense
                outlined
              ></v-text-field>
            </v-col>

            <v-col md="6" cols="12" v-if="drivers.id_jenisusaha != '1a2040e8-c61f-4d66-a93e-057320e1ee6d'">
              <v-text-field
                id="nama_kelurahantoko"
                v-model="kelurahantoko.nama_kelurahan"
                class="required"
                data-bs-toggle="modal"
                data-bs-target="#ModalKelurahanToko"
                label="Kelurahan"
                dense
                outlined
                readonly
              ></v-text-field>

              <text
                id="id_kelurahantoko"
                v-model="kelurahantoko.id_kelurahan"
                class="required"
                data-bs-toggle="modal"
                data-bs-target="#ModalKelurahanToko"
                label="Kelurahan"
                dense
                outlined
                readonly
                type="hidden"
              ></text>
            </v-col>

            <v-col md="12" cols="12" v-if="drivers.id_jenisusaha != '1a2040e8-c61f-4d66-a93e-057320e1ee6d'">
              <div id="mapContainerToko" style="width: 100%; height: 480px"></div>
            </v-col>

            <v-col md="6" cols="12" v-if="drivers.id_jenisusaha != '1a2040e8-c61f-4d66-a93e-057320e1ee6d'">
              <v-col md="6" cols="12">
                <v-header>Longitude</v-header>
              </v-col>
              <v-text-field id="lngtoko" class="required" dense outlined readonly></v-text-field>
            </v-col>

            <v-col md="6" cols="12" v-if="drivers.id_jenisusaha != '1a2040e8-c61f-4d66-a93e-057320e1ee6d'">
              <v-col md="6" cols="12">
                <v-header>Latitude</v-header>
              </v-col>
              <v-text-field id="lattoko" class="required" dense outlined readonly></v-text-field>
            </v-col>

            <v-col md="6" cols="12" v-if="drivers.id_jenisusaha != '1a2040e8-c61f-4d66-a93e-057320e1ee6d'">
              <v-text-field
                v-model="tokos.no_rekening"
                label="Nomor Rekening"
                class="required"
                dense
                outlined
              ></v-text-field>
            </v-col>

            <v-col md="6" cols="12" v-if="drivers.id_jenisusaha != '1a2040e8-c61f-4d66-a93e-057320e1ee6d'">
              <v-text-field v-model="tokos.nama_bank" label="Nama Bank" class="required" dense outlined></v-text-field>
            </v-col>

            <v-col md="12" cols="12" v-if="drivers.id_jenisusaha != '1a2040e8-c61f-4d66-a93e-057320e1ee6d'">
              <v-card-text class="text-no-wrap pt-1 ps-1"> Logo</v-card-text>
              <div class="cropper-area">
                <div class="img-cropper">
                  <vue-cropper
                    ref="cropperLogoToko"
                    :key="showPhotoLogoToko"
                    :aspect-ratio="4 / 2"
                    :src="showPhotoLogoToko()"
                    preview=".preview"
                  />
                </div>
              </div>

              <v-btn type="submit" color="primary" @click.prevent="showFileChooserLogoToko" class="me-3 mt-4">
                Choose Image
              </v-btn>
              <v-btn type="submit" color="primary" @click.prevent="cropImageLogoToko" class="me-3 mt-4"> Crop </v-btn>

              <input
                style="display: none"
                ref="inputLogoToko"
                type="file"
                accept="image/*"
                @change="setImageLogoToko"
              />
              <br />
              <br />
              <v-card-text class="text-no-wrap pt-1 ps-1"> Crop Logo</v-card-text>
              <section class="preview-area">
                <!-- <p>Cover</p> -->
                <div class="cropped-image">
                  <img width="200px" :src="updatePhotoLogoToko()" />
                  <!-- <img width="200px" :src="clients.photo == '' ? defautlPhoto() : clients.photo" alt="Cropped Image" /> -->
                </div>
              </section>
            </v-col>

            <v-col md="12" cols="12" v-if="drivers.id_jenisusaha != '1a2040e8-c61f-4d66-a93e-057320e1ee6d'">
              <v-card-text class="text-no-wrap pt-1 ps-1">Photo Toko</v-card-text>
              <div class="cropper-area">
                <div class="img-cropper">
                  <vue-cropper
                    ref="cropperToko"
                    :key="showPhotoToko"
                    :aspect-ratio="4 / 2"
                    :src="showPhotoToko()"
                    preview=".preview"
                  />
                </div>
              </div>

              <v-btn type="submit" color="primary" @click.prevent="showFileChooserToko" class="me-3 mt-4">
                Choose Image
              </v-btn>
              <v-btn type="submit" color="primary" @click.prevent="cropImageToko" class="me-3 mt-4"> Crop </v-btn>

              <input style="display: none" ref="inputToko" type="file" accept="image/*" @change="setImageToko" />
              <br />
              <br />
              <v-card-text class="text-no-wrap pt-1 ps-1"> Crop Photo Toko</v-card-text>
              <section class="preview-area">
                <!-- <p>Cover</p> -->
                <div class="cropped-image">
                  <img width="200px" :src="updatePhotoToko()" />
                  <!-- <img width="200px" :src="clients.photo == '' ? defautlPhoto() : clients.photo" alt="Cropped Image" /> -->
                </div>
              </section>
            </v-col>

            <!-- End Toko -->

            <v-col md="6" cols="12">
              <v-text-field
                id="nama_kategorimember"
                v-model="member.nama_kategorimember"
                class="required"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                label="Kategori Member"
                dense
                outlined
                readonly
              ></v-text-field>

              <text
                id="id_kategorimember"
                v-model="member.id_kategorimember"
                class="required"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                label="Kategori Member"
                dense
                outlined
                readonly
                type="hidden"
              ></text>
            </v-col>

            <v-col md="6" cols="12">
              <v-text-field
                v-model="member.nama_lengkap"
                label="Nama Lengkap"
                class="required"
                dense
                outlined
              ></v-text-field>
            </v-col>

            <v-col md="6" cols="12">
              <v-header>Jenis Kelamin</v-header>
              <v-radio-group v-model="member.jenis_kelamin" row>
                <v-radio label="Laki-laki" value="L" color="info"></v-radio>
                <v-radio label="Perempuan" value="P" color="error"></v-radio>
              </v-radio-group>
            </v-col>

            <v-col md="6" cols="12">
              <v-text-field v-model="member.no_ktp" label="No KTP" class="required" dense outlined></v-text-field>
            </v-col>

            <v-col md="6" cols="12">
              <v-text-field v-model="member.alamat" label="Alamat" class="required" dense outlined></v-text-field>
            </v-col>

            <v-col md="6" cols="12">
              <v-text-field
                id="nama_kelurahan"
                v-model="member.nama_kelurahan"
                class="required"
                data-bs-toggle="modal"
                data-bs-target="#ModalKelurahan"
                label="Kelurahan"
                dense
                outlined
                readonly
              ></v-text-field>

              <text
                id="id_kelurahan"
                v-model="member.id_kelurahan"
                class="required"
                data-bs-toggle="modal"
                data-bs-target="#ModalKelurahan"
                label="Kelurahan"
                dense
                outlined
                readonly
                type="hidden"
              ></text>
            </v-col>

            <!-- <v-col md="6" cols="12">
              <v-text-field
                label="Longitude"
                class="required"
                v-model="clients.Longitude"
                dense
                outlined
              ></v-text-field>
            </v-col>

            <v-col md="6" cols="12">
              <v-text-field label="Latitude" class="required" v-model="clients.latitude" dense outlined></v-text-field>
            </v-col> -->
            <v-col md="12" cols="12">
              <div id="mapContainer" style="width: 100%; height: 480px"></div>
            </v-col>

            <v-col md="6" cols="12">
              <v-col md="6" cols="12">
                <v-header>Longitude</v-header>
              </v-col>
              <v-text-field id="lng" class="required" dense outlined readonly></v-text-field>
            </v-col>

            <v-col md="6" cols="12">
              <v-col md="6" cols="12">
                <v-header>Latitude</v-header>
              </v-col>
              <v-text-field id="lat" class="required" dense outlined readonly></v-text-field>
            </v-col>

            <v-col md="6" cols="12">
              <v-header>Agama</v-header>
              <v-radio-group v-model="member.agama" row>
                <v-radio label="Islam" value="Islam" color="info"></v-radio>
                <v-radio label="Kristen" value="Kristen" color="info"></v-radio>
                <v-radio label="Katholik" value="Katholik" color="info"></v-radio>
                <v-radio label="Hindu" value="Hindu" color="info"></v-radio>
                <v-radio label="Budha" value="Budha" color="info"></v-radio>
                <v-radio label="Konghucu" value="Konghucu" color="info"></v-radio>
                <v-radio label="Kepercayaan" value="Kepercayaan" color="info"></v-radio>
              </v-radio-group>
            </v-col>

            <v-col md="6" cols="12">
              <v-text-field
                class="required"
                :rules="[rulesemail.email]"
                v-model="member.email"
                label="Email"
                :prepend-inner-icon="icons.mdiEmailOutline"
                dense
                outlined
              ></v-text-field>
            </v-col>

            <v-col md="6" cols="12">
              <v-text-field
                class="required"
                v-model="member.telp"
                label="Telp (tanpa 062 / +62)"
                :prepend-inner-icon="icons.mdiCellphone"
                dense
                outlined
              ></v-text-field>
            </v-col>

            <v-col md="6" cols="12">
              <v-text-field
                class="required"
                v-model="member.whatsapp"
                label=" Whatsapp (tanpa 062 / +62)"
                :prepend-inner-icon="icons.mdiCellphone"
                dense
                outlined
              ></v-text-field>
            </v-col>

            <v-col md="6" cols="12">
              <v-card-text class="text-no-wrap pt-1 ps-1"> Photo</v-card-text>
              <!-- <v-img :lazy-src="showPhoto()" max-height="150" max-width="150" :src="showPhoto()"></v-img> -->
              <div class="cropper-area">
                <div class="img-cropper">
                  <vue-cropper
                    ref="cropper"
                    :key="showPhoto"
                    :aspect-ratio="2 / 2"
                    :src="showPhoto()"
                    preview=".preview"
                  />
                </div>
              </div>

              <v-btn type="submit" color="primary" @click.prevent="showFileChooser" class="me-3 mt-4">
                Choose Image
              </v-btn>
              <v-btn type="submit" color="primary" @click.prevent="cropImage" class="me-3 mt-4"> Crop </v-btn>

              <input style="display: none" ref="input" type="file" accept="image/*" @change="setImage" />
              <br />
              <br />
              <v-card-text class="text-no-wrap pt-1 ps-1"> Crop Photo</v-card-text>
              <section class="preview-area">
                <!-- <p>Cover</p> -->
                <div class="cropped-image">
                  <img width="200px" :src="updatePhoto()" />
                  <!-- <img width="200px" :src="clients.photo == '' ? defautlPhoto() : clients.photo" alt="Cropped Image" /> -->
                </div>
              </section>
            </v-col>

            <v-col cols="12">
              <v-btn type="submit" color="primary" class="me-3 mt-4"> Save </v-btn>

              <router-link :to="{ name: 'members-index' }">
                <v-btn color="secondary" outlined class="mt-4" type="reset"> Cancel </v-btn>
              </router-link>
            </v-col>
          </v-row>
        </form>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { mdiCheckBold, mdiEmailOutline, mdiCellphone, mdiCamera, mdiSlashForward } from '@mdi/js'
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'

export default {
  components: {
    VueCropper,
  },
  data() {
    return {
      breadcrumbs: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/admin/dashboard',
        },
        {
          text: 'Member',
          disabled: false,
          href: '/admin/members',
        },
        {
          text: 'Create Member',
          disabled: true,
        },
      ],

      icons: {
        mdiCheckBold,
        mdiEmailOutline,
        mdiCellphone,
        mdiCamera,
        mdiSlashForward,
      },
      rulesimage: [value => !value || value.size < 2000000 || 'Photo size should be less than 2 MB!'],

      rulesemail: {
        // required: value => !!value || 'Required.',
        // counter: value => value.length <= 20 || 'Max 20 characters',
        email: value => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        },
      },

      member: {
        id_kategorimember: '',
        no_ktp: '',
        nama_lengkap: '',
        alamat: '',
        id_kelurahan: '',
        jenis_kelamin: 'L',
        agama: 'Islam',
        email: '',
        telp: '',
        whatsapp: '',
        photo: '',
        lat: '',
        lng: '',
      },

      merkmotor: ['Honda', 'Yamaha', 'Suzuki', 'Kawasaki', 'Minerva', 'Vespa'],
      errors: [],

      kategorimember: [],
      kelurahan: [],
      memberjenisusaha: [],
      jenisusaha: [],

      drivers: {
        no_platmotor: '',
        merk_kendaraan: '',
        tahun_pembuatan_kendaraan: '',
        photo_sim: '',
        photo_dengan_sim: '',
        no_rekening: '',
        nama_bank: '',
        aktif: 'Y',
        blokir: 'T',
      },

      tokos: {
        nama_toko: '',
        nama_kelurahan: '',
        id_kelurahan: '',
        lattoko: '',
        lngtoko: '',
        logo_toko: '',
        no_rekening: '',
        nama_bank: '',
        photo_toko: '',
      },

      kelurahantoko: [],

      page: 1,
      perPage: 10,
      pages: [],
      search: '',
      searchResult: [],

      pageNegaraAsal: 1,
      perPageNegaraAsal: 10,
      pagesNegaraAsal: [],
      searchNegaraAsal: '',
      searchResultNegaraAsal: [],
    }
  },
  watch: {
    kategorimember() {
      this.pages = []
      this.setPages()
    },

    kelurahan() {
      this.pagesNegaraAsal = []
      this.setPagesDataNegaraAsal()
    },
  },
  mounted() {
    this.$isLoading(true)
    this.getAllMember()
    this.GetAllMemberJenisUsaha()
    this.getAllJenisUsaha()
    this.GetAllToko()

    setTimeout(() => {
      this.GetAllDriver()
      this.getKategoriMember()
      this.getKelurahan()
      this.$isLoading(false)
    }, 2000)
  },
  filters: {
    capitalize: function (data) {
      var capitalized = []
      data.split(' ').forEach(word => {
        capitalized.push(word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      })
      return capitalized.join(' ')
    },
  },
  methods: {
    ClearData() {
      // console.log('DATA AKTIF : ' + this.drivers.aktif)

      if (this.drivers.id_jenisusaha != '1a2040e8-c61f-4d66-a93e-057320e1ee6d') {
        this.drivers.no_platmotor = ''
        this.drivers.merk_kendaraan = ''
        this.drivers.tahun_pembuatan_kendaraan = ''
        this.drivers.photo_sim = ''
        this.drivers.photo_dengan_sim = ''
        this.drivers.no_rekening = ''
        this.drivers.nama_bank = ''
        if (this.tokos.id_toko != null) {
          this.GetAllToko()
        }
      } else {
        // this.GetAllDriver()
        this.nama_toko = ''
        this.nama_kelurahan = ''
        this.id_kelurahan = ''
        this.lattoko = ''
        this.no_rekening = ''
        this.nama_bank = ''
        this.photo_toko = ''

        if (this.drivers.id_driver != null) {
          this.GetAllDriver()
        } else {
          this.drivers.aktif = 'Y'
          this.drivers.blokir = 'T'
        }
      }
    },

    getAllJenisUsaha() {
      //state token
      localStorage.getItem('token')

      let url = process.env.VUE_APP_ROOT_API + '/api/admin/jenisusahas'
      return this.axios.get(url).then(response => {
        this.jenisusaha = response.data.jenisusahas
      })
    },

    GetAllDriver() {
      // let uri = `/api/admin/roles/${this.$route.params.id_role}/show`
      let uri = process.env.VUE_APP_ROOT_API + `/api/admin/driver/${this.$route.params.id_member}/show`
      this.axios.get(uri).then(response => {
        this.drivers = response.data.driver
        // console.log(this.role.nama_role)
      })
    },

    GetAllToko() {
      // let uri = `/api/admin/roles/${this.$route.params.id_role}/show`
      let uri = process.env.VUE_APP_ROOT_API + `/api/admin/toko/${this.$route.params.id_member}/show`
      this.axios.get(uri).then(response => {
        this.tokos = response.data.toko
        this.kelurahantoko = response.data.kelurahan

        let latitude = ''
        let longitude = ''
        // this.axios.get(uri).then(response => {
        //   this.tokos = response.data.toko

        this.latitude = response.data.toko.latitude_toko
        this.longitude = response.data.toko.longitude_toko

        // console.log('LONGITUDE : ' + this.longitude)
        const { tokos } = this

        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(position => {
            let localCoord = position.coords
            let objLocalCoord = {
              lat: this.latitude,
              lng: this.longitude,
            }

            document.getElementById('lattoko').value = this.latitude
            document.getElementById('lngtoko').value = this.longitude

            // Initialize the platform object:
            let platform = new H.service.Platform({
              apikey: 'BVnA0J-kRSraSgL_On6Vn3MayVHlJn9A16APycW1FPA',
            })

            // Obtain the default map types from the platform object
            let defaultLayers = platform.createDefaultLayers()

            // Instantiate (and display) a map object:
            let map = new H.Map(document.getElementById('mapContainerToko'), defaultLayers.vector.normal.map, {
              zoom: 17,
              center: objLocalCoord,
              pixelRatio: window.devicePixelRatio || 1,
            })
            window.addEventListener('resize', () => map.getViewPort().resize())

            let ui = H.ui.UI.createDefault(map, defaultLayers)
            let mapEvents = new H.mapevents.MapEvents(map)
            let behavior = new H.mapevents.Behavior(mapEvents)

            // Draggable marker function
            function addDragableMarker(map, behavior) {
              let inputLat = document.getElementById('lattoko')
              let inputLng = document.getElementById('lngtoko')

              if (inputLat.value != '' && inputLng.value != '') {
                objLocalCoord = {
                  lat: inputLat.value,
                  lng: inputLng.value,
                }
                // document.querySelector('#latitude').setAttribute('value', inputLat.value);
                // document.querySelector('#longitude').setAttribute('value', inputLng.value);
              }

              let marker = new H.map.Marker(objLocalCoord, {
                volatility: true,
              })
              marker.draggable = true
              map.addObject(marker)

              // disable the default draggability of the underlying map
              // and calculate the offset between mouse and target's position
              // when starting to drag a marker object
              map.addEventListener(
                'dragstart',
                function (ev) {
                  let target = ev.target,
                    pointer = ev.currentPointer
                  if (target instanceof H.map.Marker) {
                    let targetPosition = map.geoToScreen(target.getGeometry())
                    target['offset'] = new H.math.Point(
                      pointer.viewportX - targetPosition.x,
                      pointer.viewportY - targetPosition.y,
                    )
                    behavior.disable()
                  }
                },
                false,
              )

              // Listen to the drag event and move the position of the marker
              // and necessary
              map.addEventListener(
                'drag',
                function (ev) {
                  let target = ev.target,
                    pointer = ev.currentPointer
                  if (target instanceof H.map.Marker) {
                    target.setGeometry(
                      map.screenToGeo(pointer.viewportX - target['offset'].x, pointer.viewportY - target['offset'].y),
                    )
                  }
                },
                false,
              )

              // re-enable the default draggability of the underlying map
              // when dragging has complete
              map.addEventListener(
                'dragend',
                function (ev) {
                  let target = ev.target
                  if (target instanceof H.map.Marker) {
                    behavior.enable()
                    let resultCoord = map.screenToGeo(ev.currentPointer.viewportX, ev.currentPointer.viewportY)
                    // console.log(resultCoord)
                    inputLat.value = resultCoord.lat.toFixed(5)
                    inputLng.value = resultCoord.lng.toFixed(5)
                  }
                },
                false,
              )
            }

            // Call this function
            addDragableMarker(map, behavior)

            // window.action ada di create.blade.php
            if (window.action == 'submit') {
              addDragableMarker(map, behavior)
            }

            // Browse location codespace
            let spaces = []

            const fetchSpaces = function (latitude, longitude, radius) {
              return new Promise(function (resolve, reject) {
                resolve(
                  fetch(`/api/spaces?lat=${latitude}&lng=${longitude}&rad=${radius}`)
                    .then(res => res.json())
                    .then(function (data) {
                      data.forEach(function (value, index) {
                        let marker = new H.map.Marker({
                          lat: value.latitude,
                          lng: value.longitude,
                        })
                        spaces.push(marker)
                      })
                    }),
                )
              })
            }

            function clearSpace() {
              map.removeObjects(spaces)
              spaces = []
            }

            function init(latitude, longitude, radius) {
              clearSpace()
              fetchSpaces(latitude, longitude, radius).then(() => {
                map.addObjects(spaces)
              })
            }

            if (window.action == 'browse') {
              map.addEventListener(
                'dragend',
                function (ev) {
                  let resultCoord = map.screenToGeo(ev.currentPointer.viewportX, ev.currentPointer.viewportY)
                  init(resultCoord.lat, resultCoord.lng, 40)
                },
                false,
              )

              init(objLocalCoord.lat, objLocalCoord.lng, 40)
            }
          })
        } else {
          console.log(error)
        }
        this.tokos = tokos
        // this.longitude = this.member.longitude
      })
    },

    GetAllMemberJenisUsaha() {
      // let uri = `/api/admin/roles/${this.$route.params.id_role}/show`
      let uri = process.env.VUE_APP_ROOT_API + `/api/admin/memberjenisusaha/${this.$route.params.id_member}/show`
      this.axios.get(uri).then(response => {
        this.memberjenisusaha = response.data.memberjenisusahas
        // console.log(this.role.nama_role)
      })
    },
    async getAllMember() {
      // let uri = `/api/admin/roles/${this.$route.params.id_role}/show`
      let uriMembers = process.env.VUE_APP_ROOT_API + `/api/admin/members/${this.$route.params.id_member}/show`
      let latitude = ''
      let longitude = ''
      this.axios.get(uriMembers).then(response => {
        this.member = response.data.members

        this.latitude = response.data.members.latitude
        this.longitude = response.data.members.longitude

        const { member } = this

        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(position => {
            let localCoord = position.coords
            let objLocalCoord = {
              lat: this.latitude,
              lng: this.longitude,
            }

            document.getElementById('lat').value = this.latitude
            document.getElementById('lng').value = this.longitude

            // Initialize the platform object:
            let platform = new H.service.Platform({
              apikey: 'BVnA0J-kRSraSgL_On6Vn3MayVHlJn9A16APycW1FPA',
            })

            // Obtain the default map types from the platform object
            let defaultLayers = platform.createDefaultLayers()

            // Instantiate (and display) a map object:
            let map = new H.Map(document.getElementById('mapContainer'), defaultLayers.vector.normal.map, {
              zoom: 17,
              center: objLocalCoord,
              pixelRatio: window.devicePixelRatio || 1,
            })
            window.addEventListener('resize', () => map.getViewPort().resize())

            let ui = H.ui.UI.createDefault(map, defaultLayers)
            let mapEvents = new H.mapevents.MapEvents(map)
            let behavior = new H.mapevents.Behavior(mapEvents)

            // Draggable marker function
            function addDragableMarker(map, behavior) {
              let inputLat = document.getElementById('lat')
              let inputLng = document.getElementById('lng')

              if (inputLat.value != '' && inputLng.value != '') {
                objLocalCoord = {
                  lat: inputLat.value,
                  lng: inputLng.value,
                }
                // document.querySelector('#latitude').setAttribute('value', inputLat.value);
                // document.querySelector('#longitude').setAttribute('value', inputLng.value);
              }

              let marker = new H.map.Marker(objLocalCoord, {
                volatility: true,
              })
              marker.draggable = true
              map.addObject(marker)

              // disable the default draggability of the underlying map
              // and calculate the offset between mouse and target's position
              // when starting to drag a marker object
              map.addEventListener(
                'dragstart',
                function (ev) {
                  let target = ev.target,
                    pointer = ev.currentPointer
                  if (target instanceof H.map.Marker) {
                    let targetPosition = map.geoToScreen(target.getGeometry())
                    target['offset'] = new H.math.Point(
                      pointer.viewportX - targetPosition.x,
                      pointer.viewportY - targetPosition.y,
                    )
                    behavior.disable()
                  }
                },
                false,
              )

              // Listen to the drag event and move the position of the marker
              // and necessary
              map.addEventListener(
                'drag',
                function (ev) {
                  let target = ev.target,
                    pointer = ev.currentPointer
                  if (target instanceof H.map.Marker) {
                    target.setGeometry(
                      map.screenToGeo(pointer.viewportX - target['offset'].x, pointer.viewportY - target['offset'].y),
                    )
                  }
                },
                false,
              )

              // re-enable the default draggability of the underlying map
              // when dragging has complete
              map.addEventListener(
                'dragend',
                function (ev) {
                  let target = ev.target
                  if (target instanceof H.map.Marker) {
                    behavior.enable()
                    let resultCoord = map.screenToGeo(ev.currentPointer.viewportX, ev.currentPointer.viewportY)
                    // console.log(resultCoord)
                    inputLat.value = resultCoord.lat.toFixed(5)
                    inputLng.value = resultCoord.lng.toFixed(5)
                  }
                },
                false,
              )
            }

            // Call this function
            addDragableMarker(map, behavior)

            // window.action ada di create.blade.php
            if (window.action == 'submit') {
              addDragableMarker(map, behavior)
            }

            // Browse location codespace
            let spaces = []

            const fetchSpaces = function (latitude, longitude, radius) {
              return new Promise(function (resolve, reject) {
                resolve(
                  fetch(`/api/spaces?lat=${latitude}&lng=${longitude}&rad=${radius}`)
                    .then(res => res.json())
                    .then(function (data) {
                      data.forEach(function (value, index) {
                        let marker = new H.map.Marker({
                          lat: value.latitude,
                          lng: value.longitude,
                        })
                        spaces.push(marker)
                      })
                    }),
                )
              })
            }

            function clearSpace() {
              map.removeObjects(spaces)
              spaces = []
            }

            function init(latitude, longitude, radius) {
              clearSpace()
              fetchSpaces(latitude, longitude, radius).then(() => {
                map.addObjects(spaces)
              })
            }

            if (window.action == 'browse') {
              map.addEventListener(
                'dragend',
                function (ev) {
                  let resultCoord = map.screenToGeo(ev.currentPointer.viewportX, ev.currentPointer.viewportY)
                  init(resultCoord.lat, resultCoord.lng, 40)
                },
                false,
              )

              init(objLocalCoord.lat, objLocalCoord.lng, 40)
            }
          })
        } else {
          console.log(error)
        }
        this.member = member
        // this.longitude = this.member.longitude
      })
    },

    getKategoriMember() {
      // state token
      localStorage.getItem('token')
      const url = `${process.env.VUE_APP_ROOT_API}/api/admin/kategorimember/penjual`
      return this.axios.get(url).then(response => {
        this.kategorimember = response.data.kategorimembers
      })
    },

    getKelurahan() {
      // state token
      localStorage.getItem('token')
      const uriNegara = `${process.env.VUE_APP_ROOT_API}/api/admin/kelurahans`
      return this.axios.get(uriNegara).then(response => {
        this.kelurahan = response.data.kelurahans
      })
    },

    updatePhoto() {
      if (this.member.photo == null || this.member.photo == '') {
        // return "../../../../images/guru/no-image.jpg";
        let imageDefault = process.env.VUE_APP_ROOT_API + `/images/member/no-image.png`
        return imageDefault
      }

      let img = this.member.photo

      if (img.length > 100) {
        return this.member.photo
      } else {
        let ImageMembers = process.env.VUE_APP_ROOT_API + `/images/member/${this.member.id_member}/${this.member.photo}`
        return ImageMembers
      }
    },

    showPhoto() {
      if (typeof this.member.photo === 'string' && this.member.photo.length != 0) {
        if (this.member.photo.length > 100) {
          return this.member.photo
        }
        let ImageMembers = process.env.VUE_APP_ROOT_API + `/images/member/${this.member.id_member}/${this.member.photo}`
        return ImageMembers
      }
      let imageDefault = process.env.VUE_APP_ROOT_API + `/images/member/default_img.png`
      return imageDefault
    },

    setImage(e) {
      const file = e.target.files[0]
      if (file.type.indexOf('image/') === -1) {
        alert('Please select an image file')
        return
      }
      if (typeof FileReader === 'function') {
        const reader = new FileReader()
        reader.onload = event => {
          this.member.photo = event.target.result
          // rebuild cropperjs with the updated source
          this.$refs.cropper.replace(event.target.result)
        }
        reader.readAsDataURL(file)
      } else {
        alert('Sorry, FileReader API not supported')
      }
    },
    cropImage() {
      // get image data for post processing, e.g. upload or setting image src
      this.member.photo = this.$refs.cropper.getCroppedCanvas().toDataURL()
    },
    showFileChooser() {
      this.$refs.input.click()
    },

    // Bawa SIM
    updatePhotoBawaSim() {
      if (this.drivers.photo_dengan_sim == null || this.drivers.photo_dengan_sim == '') {
        // return "../../../../images/guru/no-image.jpg";
        let imageDefault = process.env.VUE_APP_ROOT_API + `/images/member/default_img.png`
        return imageDefault
      }

      let img = this.drivers.photo_dengan_sim

      if (img.length > 100) {
        return this.drivers.photo_dengan_sim
      } else {
        let ImageMembers =
          process.env.VUE_APP_ROOT_API + `/images/driver/${this.drivers.id_driver}/${this.drivers.photo_dengan_sim}`
        return ImageMembers
      }
    },

    showPhotoBawaSim() {
      if (typeof this.drivers.photo_dengan_sim === 'string' && this.drivers.photo_dengan_sim.length != 0) {
        if (this.drivers.photo_dengan_sim.length > 100) {
          return this.drivers.photo_dengan_sim
        }
        let ImageMembers =
          process.env.VUE_APP_ROOT_API + `/images/driver/${this.drivers.id_driver}/${this.drivers.photo_dengan_sim}`
        return ImageMembers
      }
      let imageDefault = process.env.VUE_APP_ROOT_API + `/images/member/default_img.png`
      return imageDefault
    },

    setImageBawaSim(e) {
      const file = e.target.files[0]
      if (file.type.indexOf('image/') === -1) {
        alert('Please select an image file')
        return
      }
      if (typeof FileReader === 'function') {
        const reader = new FileReader()
        reader.onload = event => {
          this.drivers.photo_dengan_sim = event.target.result
          // rebuild cropperjs with the updated source
          this.$refs.cropperBawaSim.replace(event.target.result)
        }
        reader.readAsDataURL(file)
      } else {
        alert('Sorry, FileReader API not supported')
      }
    },
    cropImageBawaSim() {
      // get image data for post processing, e.g. upload or setting image src
      this.drivers.photo_dengan_sim = this.$refs.cropperBawaSim.getCroppedCanvas().toDataURL()
    },
    showFileChooserBawaSim() {
      this.$refs.inputBawaSim.click()
    },

    // SIM
    updatePhotoSim() {
      if (this.drivers.photo_sim == null || this.drivers.photo_sim == '') {
        // return "../../../../images/guru/no-image.jpg";
        let imageDefault = process.env.VUE_APP_ROOT_API + `/images/member/default_img.png`
        return imageDefault
      }

      let img = this.drivers.photo_sim

      if (img.length > 100) {
        return this.drivers.photo_sim
      } else {
        let ImageMembers =
          process.env.VUE_APP_ROOT_API + `/images/driver/${this.drivers.id_driver}/${this.drivers.photo_sim}`
        return ImageMembers
      }
    },

    showPhotoSim() {
      if (typeof this.drivers.photo_sim === 'string' && this.drivers.photo_sim.length != 0) {
        if (this.drivers.photo_sim.length > 100) {
          return this.drivers.photo_sim
        }
        let ImageMembers =
          process.env.VUE_APP_ROOT_API + `/images/driver/${this.drivers.id_driver}/${this.drivers.photo_sim}`
        return ImageMembers
      }
      let imageDefault = process.env.VUE_APP_ROOT_API + `/images/member/default_img.png`
      return imageDefault
    },

    setImageSim(e) {
      const file = e.target.files[0]
      if (file.type.indexOf('image/') === -1) {
        alert('Please select an image file')
        return
      }
      if (typeof FileReader === 'function') {
        const reader = new FileReader()
        reader.onload = event => {
          this.drivers.photo_sim = event.target.result
          // rebuild cropperjs with the updated source
          this.$refs.cropperSim.replace(event.target.result)
        }
        reader.readAsDataURL(file)
      } else {
        alert('Sorry, FileReader API not supported')
      }
    },
    cropImageSim() {
      // get image data for post processing, e.g. upload or setting image src
      this.drivers.photo_sim = this.$refs.cropperSim.getCroppedCanvas().toDataURL()
    },
    showFileChooserSim() {
      this.$refs.inputSim.click()
    },

    // Logo
    updatePhotoLogoToko() {
      if (this.tokos.logo_toko == null || this.tokos.logo_toko == '') {
        // return "../../../../images/guru/no-image.jpg";
        let imageDefault = process.env.VUE_APP_ROOT_API + `/images/member/default_img.png`
        return imageDefault
      }

      let img = this.tokos.logo_toko

      if (img.length > 100) {
        return this.tokos.logo_toko
      } else {
        let ImageMembers = process.env.VUE_APP_ROOT_API + `/images/toko/${this.tokos.id_toko}/${this.tokos.logo_toko}`
        return ImageMembers
      }
    },

    showPhotoLogoToko() {
      if (typeof this.tokos.logo_toko === 'string' && this.tokos.logo_toko.length != 0) {
        if (this.tokos.logo_toko.length > 100) {
          return this.tokos.logo_toko
        }
        let ImageMembers = process.env.VUE_APP_ROOT_API + `/images/toko/${this.tokos.id_toko}/${this.tokos.logo_toko}`
        return ImageMembers
      }
      let imageDefault = process.env.VUE_APP_ROOT_API + `/images/member/default_img.png`
      return imageDefault
    },

    setImageLogoToko(e) {
      const file = e.target.files[0]
      if (file.type.indexOf('image/') === -1) {
        alert('Please select an image file')
        return
      }
      if (typeof FileReader === 'function') {
        const reader = new FileReader()
        reader.onload = event => {
          this.tokos.logo_toko = event.target.result
          // rebuild cropperjs with the updated source
          this.$refs.cropperLogoToko.replace(event.target.result)
        }
        reader.readAsDataURL(file)
      } else {
        alert('Sorry, FileReader API not supported')
      }
    },
    cropImageLogoToko() {
      // get image data for post processing, e.g. upload or setting image src
      this.tokos.logo_toko = this.$refs.cropperLogoToko.getCroppedCanvas().toDataURL()
    },
    showFileChooserLogoToko() {
      this.$refs.inputLogoToko.click()
    },

    // Photo Toko
    updatePhotoToko() {
      if (this.tokos.photo_toko == null || this.tokos.photo_toko == '') {
        // return "../../../../images/guru/no-image.jpg";
        let imageDefault = process.env.VUE_APP_ROOT_API + `/images/member/default_img.png`
        return imageDefault
      }

      let img = this.tokos.photo_toko

      if (img.length > 100) {
        return this.tokos.photo_toko
      } else {
        let ImageMembers = process.env.VUE_APP_ROOT_API + `/images/toko/${this.tokos.id_toko}/${this.tokos.photo_toko}`
        return ImageMembers
      }
    },

    showPhotoToko() {
      if (typeof this.tokos.photo_toko === 'string' && this.tokos.photo_toko.length != 0) {
        if (this.tokos.photo_toko.length > 100) {
          return this.tokos.photo_toko
        }
        let ImageMembers = process.env.VUE_APP_ROOT_API + `/images/toko/${this.tokos.id_toko}/${this.tokos.photo_toko}`
        return ImageMembers
      }
      let imageDefault = process.env.VUE_APP_ROOT_API + `/images/member/default_img.png`
      return imageDefault
    },

    setImageToko(e) {
      const file = e.target.files[0]
      if (file.type.indexOf('image/') === -1) {
        alert('Please select an image file')
        return
      }
      if (typeof FileReader === 'function') {
        const reader = new FileReader()
        reader.onload = event => {
          this.tokos.photo_toko = event.target.result
          // rebuild cropperjs with the updated source
          this.$refs.cropperToko.replace(event.target.result)
        }
        reader.readAsDataURL(file)
      } else {
        alert('Sorry, FileReader API not supported')
      }
    },
    cropImageToko() {
      // get image data for post processing, e.g. upload or setting image src
      this.tokos.photo_toko = this.$refs.cropperToko.getCroppedCanvas().toDataURL()
    },
    showFileChooserToko() {
      this.$refs.inputToko.click()
    },

    CreateData(e) {
      // console.log('PHOTO: ' + this.clients.photo)
      if (this.$data.member.no_ktp.length === 0) {
        // this.errors.push('Nama Role harus diisi !')
        this.$swal.fire({
          title: 'Error',
          text: 'Nomor KTP required !',
          icon: 'error',
          showCancelButton: false,
          showConfirmButton: false,
          timer: 1000,
        })
      } else if (this.$data.member.id_kategorimember.length === 0) {
        this.$swal.fire({
          title: 'Error',
          text: 'Kategori member required !',
          icon: 'error',
          showCancelButton: false,
          showConfirmButton: false,
          timer: 1000,
        })
      } else if (this.$data.member.alamat.length === 0) {
        this.$swal.fire({
          title: 'Error',
          text: 'Alamat required !',
          icon: 'error',
          showCancelButton: false,
          showConfirmButton: false,
          timer: 1000,
        })
      } else if (this.$data.member.id_kelurahan.length === 0) {
        this.$swal.fire({
          title: 'Error',
          text: 'Kelurahan required !',
          icon: 'error',
          showCancelButton: false,
          showConfirmButton: false,
          timer: 1000,
        })
      } else if (this.$data.member.email.length === 0) {
        this.$swal.fire({
          title: 'Error',
          text: 'Citizenship required !',
          icon: 'error',
          showCancelButton: false,
          showConfirmButton: false,
          timer: 1000,
        })
      } else {
        let uri = process.env.VUE_APP_ROOT_API + `/api/admin/members/${this.$route.params.id_member}`
        if (this.drivers.id_jenisusaha != '1a2040e8-c61f-4d66-a93e-057320e1ee6d') {
          lngtoko = document.querySelector('#lngtoko').value
          lattoko = document.querySelector('#lattoko').value
        }

        this.axios
          .post(uri, {
            id_kategorimember: this.member.id_kategorimember,
            no_ktp: this.member.no_ktp,
            nama_lengkap: this.member.nama_lengkap,
            alamat: this.member.alamat,
            id_kelurahan: this.member.id_kelurahan,
            longitude: document.querySelector('#lng').value,
            latitude: document.querySelector('#lat').value,
            jenis_kelamin: this.member.jenis_kelamin,
            agama: this.member.agama,
            email: this.member.email,
            telp: this.member.telp,
            whatsapp: this.member.whatsapp,
            photo: this.member.photo,

            id_jenisusaha: this.drivers.id_jenisusaha,
            no_platmotor: this.drivers.no_platmotor,
            merk_kendaraan: this.drivers.merk_kendaraan,
            tahun_pembuatan_kendaraan: this.drivers.tahun_pembuatan_kendaraan,
            photo_sim: this.drivers.photo_sim,
            photo_dengan_sim: this.drivers.photo_dengan_sim,
            no_rekening: this.drivers.no_rekening,
            nama_bank: this.drivers.nama_bank,
            aktif: this.drivers.aktif,
            blokir: this.drivers.blokir,

            nama_toko: this.tokos.nama_toko,
            alamat_toko: this.tokos.alamat_toko,
            id_kelurahantoko: this.tokos.id_kelurahan,
            longitude_toko: this.lngtoko,
            latitude_toko: this.lattoko,
            logo_toko: this.tokos.logo_toko,
            no_rekeningtoko: this.tokos.no_rekening,
            nama_banktoko: this.tokos.nama_bank,
            photo_toko: this.tokos.photo_toko,
          })
          .then(response => {
            this.$swal.fire({
              title: 'Success',
              text: 'Member created successfully',
              icon: 'success',
              showCancelButton: false,
              showConfirmButton: false,
              timer: 1000,
            })

            setTimeout(() => {
              this.$router.push({ name: 'members-index' })
            }, 3000)
          })

        e.preventDefault()
      }
    },
    doSearch() {
      const go = this.kategorimember.filter(item =>
        this.search
          .toLowerCase()
          .split(' ')
          .every(v => item.nama_kategorimember.toLowerCase().includes(v)),
      )
      this.searchResult = go
      this.pages = []
      this.setPages()
    },
    displayData() {
      let paginate

      if (this.search == '') {
        paginate = this.paginate(this.kategorimember)
      } else {
        paginate = this.paginate(this.searchResult)
      }

      return paginate
    },
    setPages() {
      let numberOfPages

      if (this.search == '') {
        numberOfPages = Math.ceil(this.kategorimember.length / this.perPage)
      } else {
        numberOfPages = Math.ceil(this.searchResult.length / this.perPage)
      }
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index)
      }
    },
    paginate(kategorimember) {
      const { page } = this
      const { perPage } = this
      const from = page * perPage - perPage
      const to = page * perPage

      return kategorimember.slice(from, to)
    },

    doSearchNegaraAsal() {
      const go = this.kelurahan.filter(item =>
        this.searchNegaraAsal
          .toLowerCase()
          .split(' ')
          .every(v => item.nama_kelurahan.toLowerCase().includes(v)),
      )
      this.searchResultNegaraAsal = go
      this.pagesNegaraAsal = []
      this.setPagesDataNegaraAsal()
    },
    displayDataKeluarahan() {
      let paginateNegaraAsal

      if (this.searchNegaraAsal == '') {
        paginateNegaraAsal = this.paginateNegaraAsal(this.kelurahan)
      } else {
        paginateNegaraAsal = this.paginateNegaraAsal(this.searchResultNegaraAsal)
      }

      return paginateNegaraAsal
    },
    setPagesDataNegaraAsal() {
      let numberOfPages

      if (this.searchNegaraAsal == '') {
        numberOfPages = Math.ceil(this.kelurahan.length / this.perPageNegaraAsal)
      } else {
        numberOfPages = Math.ceil(this.searchResultNegaraAsal.length / this.perPageNegaraAsal)
      }
      for (let index = 1; index <= numberOfPages; index++) {
        this.pagesNegaraAsal.push(index)
      }
    },
    paginateNegaraAsal(kelurahan) {
      const { pageNegaraAsal } = this
      const { perPageNegaraAsal } = this
      const from = pageNegaraAsal * perPageNegaraAsal - perPageNegaraAsal
      const to = pageNegaraAsal * perPageNegaraAsal

      return kelurahan.slice(from, to)
    },

    PilihKategoriMember(id_kategorimember, nama_kategorimember) {
      document.querySelector('#id_kategorimember').setAttribute('value', id_kategorimember)
      document.querySelector('#nama_kategorimember').setAttribute('value', nama_kategorimember)
      this.member.id_kategorimember = id_kategorimember
      this.member.nama_kategorimember = nama_kategorimember
    },

    PilihKelurahan(id_kelurahan, nama_kelurahan) {
      document.querySelector('#id_kelurahan').setAttribute('value', id_kelurahan)
      document.querySelector('#nama_kelurahan').setAttribute('value', nama_kelurahan)
      this.member.id_kelurahan = id_kelurahan
      this.member.nama_kelurahan = nama_kelurahan
    },

    PilihKelurahanToko(id_kelurahan, nama_kelurahan) {
      document.querySelector('#id_kelurahantoko').setAttribute('value', id_kelurahan)
      document.querySelector('#nama_kelurahantoko').setAttribute('value', nama_kelurahan)
      this.kelurahantoko.id_kelurahan = id_kelurahan
      this.kelurahantoko.nama_kelurahan = nama_kelurahan
    },
  },
}
</script>
<style scoped>
.required >>> fieldset {
  border-color: rgba(250, 0, 29, 0.986);
}
</style>
